<template>
  <div class="col-full text-center">
    <h1>Not Found</h1>
     <router-link :to="{name: 'MyBots'}">Back to My Bots</router-link>
  </div>
</template>

<script>

</script>

<style scoped>

</style>
