<template>
  <div class="row flex-center min-vh-100 py-6">
    <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 text-center">
      
      <img class="mb-3" src="logo_horizontal.png" alt="" height="50">

      <div v-if="step == 1" class="card">
        <div class="card-body p-4 p-sm-5">
          <div class="row flex-between-center mb-2">
            <div class="col-auto">
              <h5>Reset Password</h5>
            </div>
          </div>
          <form @submit.prevent="submitSignupEmailForm">
            <div class="mb-3">
              <input v-model="form.email" class="form-control" type="email" placeholder="Email address">
              <div v-if="formValidations.email" class="invalid-feedback d-block fs-10">{{ formValidations.email }}</div>
            </div>
            <div class="mb-2 text-center">
              <button v-if="!loading" class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">Reset Password</button>
              <div v-if="loading" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div v-if="formValidations.apiError" class="invalid-feedback d-block fs-10">{{ formValidations.apiError }}</div>
            </div>
          </form>

          <router-link :to="{name: 'Login', params: {}}" class="fs-10">Go to Login</router-link>

        </div>
      </div>

      <div v-if="step == 2" class="card">
        <div class="card-body p-4 p-sm-5">
          <div class="row flex-between-center mb-2 text-center">
            <p>
              Please <span class="fw-bold">check your email</span> to confirm your password reset.<br>
              We sent it to <span class="fw-bold">{{ form.email }}</span>
            </p>

            <img class="img-fluid" src="@/assets/signup/email_icon.png" />

            <router-link :to="{name: 'ResetPassword', params: {}}" @click.prevent="startOver()" class="fs-10">Start over</router-link>

          </div>
        </div>
      </div>

      <div v-if="step == 3" class="card">
        <div class="card-body p-4 p-sm-5">
          <div class="row flex-between-center mb-2">
            <div class="col-12 text-start">
              <h5>Choose your new password</h5>
            </div>
          </div>
          <form @submit.prevent="submitSignupPasswordForm">
            <div class="mb-3">
              <input v-model="form.password" class="form-control" type="password" placeholder="Password">
              <div v-if="formValidations.password" class="invalid-feedback d-block fs-10">{{ formValidations.password }}</div>
            </div>
            <div class="mb-3 text-center">
              <button v-if="!loading" class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">Reset Password</button>
              <div v-if="loading" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div v-if="formValidations.apiError" class="invalid-feedback d-block fs-10">{{ formValidations.apiError }}</div>
            </div>
          </form>

          <router-link :to="{name: 'ResetPassword', params: {}}" @click.prevent="startOver()" class="fs-10">Start over</router-link>

        </div>
      </div>

      <div v-if="step == 4" class="card">
        <div class="card-body p-4 p-sm-5">
          <div class="row flex-between-center mb-2 text-center">
            <h4 class="mb-3">
              SUCCESS 🥳
            </h4>
            <p class="mb-0">
              Now login with your new password.<br>
              <router-link :to="{name: 'Login', params: {}}" class="btn btn-primary btn-sm mt-3" type="button">
                Go To Login
              </router-link>
            </p>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      step: 1,
      form: {
        email: '',
        token: '',
        password: ''
      },
      formValidations: {
        email: '',
        password: '',
        apiError: ''
      }
    }
  },
  methods: {
    async submitSignupEmailForm () {
      this.loading = true

      // Construct postData to send
      const postData = {
        email: this.form.email,
        passwordResetUrlTemplate: process.env.VUE_APP_URL+'/#/resetPassword?emailToken={reset_token}'
      }

      // Validate form first
      if (!this.validateSignupEmailForm()) {
        this.loading = false
        return false
      }
      
      // Make an api call
      const url = process.env.VUE_APP_API_URL+'/users/password/start_reset'
      const rawResponse = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
      })

      if (rawResponse.status === 200) {
        // Show the email info
        this.step = 2

      } else {
        try {
          const response = await rawResponse.json()
          if (response.error && response.description) {
            this.formValidations.apiError = response.description+' ('+response.error+').'
          } else {
            this.formValidations.apiError = 'An unknown error occurred.'
          }
        } catch(e) {
          this.formValidations.apiError = 'An unknown error occurred.'
        }
      }

      this.loading = false
    },
    validateSignupEmailForm () {
      this.formValidations = {}

      this.form.email = this.form.email.replace(/\s/g, '')

      if (!this.form.email || this.form.email == '') {
        this.formValidations.email = 'Please enter your email.'
        return false
      }

      if (!this.validateEmail(this.form.email)) {
        this.formValidations.email = 'This is not a valid email address.'
        return false
      }

      return true
    },
    async submitSignupPasswordForm () {
      this.loading = true

      // Construct postData to send
      const postData = {
        password: this.form.password,
        token: this.$route.query.emailToken
      }

      // Validate form first
      if (!this.validateSignupPasswordForm()) {
        this.loading = false
        return false
      }
      
      // Make an api call
      const url = process.env.VUE_APP_API_URL+'/users/password/finish_reset'
      const rawResponse = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
      })

      if (rawResponse.status === 200) {
        // const response = await rawResponse.json()
        this.step = 4
        this.$confetti.start()
        setTimeout(() => {
          this.$confetti.stop()
        }, "5000");
        // this.$router.push({ name: 'Login' })
      } else {
        try {
          const response = await rawResponse.json()
          if (response.error && response.description) {
            this.formValidations.apiError = response.description+' ('+response.error+').'
          } else {
            this.formValidations.apiError = 'An unknown error occurred.'
          }
        } catch(e) {
          this.formValidations.apiError = 'An unknown error occurred.'
        }
      }

      this.loading = false
    },
    validateSignupPasswordForm () {
      this.formValidations = {}

      // Remove spaces
      this.form.password = this.form.password.replace(/\s/g, '')

      const passwordValid = this.validatePassword(this.form.password)
      if (passwordValid.length > 0) {
        this.formValidations.password = passwordValid
        return false
      }

      return true
    },
    validateEmail (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    validatePassword (password) {
      if (!password || password == '') {
        return "Choose a password."
      }

      if (password.length < 7) {
        return "Your password must be at least 7 characters."
      }

      const passwordStrength = this.getPasswordStrength(password)
      if ( passwordStrength < 3) {
        return "Your password is not very strong. It scores "+passwordStrength+" out of 4 points. Add capital LeTtErs, special characters $@#&! and/or numb3rs to strengthen it. It's for your own safety."
      }

      return ''
    },
    getPasswordStrength(password) {
      var strength = 0
      if (password.match(/[a-z]+/)) {
        strength += 1
      }
      if (password.match(/[A-Z]+/)) {
        strength += 1
      }
      if (password.match(/[0-9]+/)) {
        strength += 1
      }
      if (password.match(/[$@#&!]+/)) {
        strength += 1
      }

      return strength
    },
    startOver () {
      // Empty all relevant obejcts
      this.form = {}
      this.tempForm = {}
      this.formValidations = {}

      this.step = 1
    }
  },
  async created () {
    // Redirect to Login when the user is logged in
    if (this.$store.getters.isUserLoggedIn) this.$router.push({ name: 'MyBots' })
  },
  async mounted () {
    // Check if the URL has the token, if so, go directly to the proper step
    const emailToken = this.$route.query.emailToken
    if (emailToken && emailToken != '') {
      this.step = 3
      this.form.token = emailToken
    }
  }
}
</script>

<style scoped>

</style>