<template>
  <div class="row flex-center min-vh-100 py-6">
    <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 text-center">
      
      <img class="mb-3" src="logo_horizontal.png" alt="" height="50">
      
      <div class="alert alert-success border-0 d-flex align-items-center text-start" role="alert">
        <div class="bg-success me-3 icon-item"><span class="fas fa-check-circle text-white fs-6"></span></div>
        <p class="mb-0 flex-1">
          <span class="fw-bold">14-Day Trial</span><br>
          Your 14-day trial starts after your first successful trade. Enjoy 🥳
        </p>
      </div>

      <div v-if="step == 1" class="card">
        <div class="card-body p-4 p-sm-5">
          <div class="row flex-between-center mb-2">
            <div class="col-auto">
              <h5>Sign up</h5>
            </div>
          </div>
          <form @submit.prevent="submitSignupEmailForm">
            <div class="mb-3">
              <input v-model="form.firstName" class="form-control" type="text" placeholder="First name">
              <div v-if="formValidations.firstName" class="invalid-feedback d-block fs-10">{{ formValidations.firstName }}</div>
            </div>
            <div class="mb-3">
              <input v-model="form.email" class="form-control" type="email" placeholder="Email address">
              <div v-if="formValidations.email" class="invalid-feedback d-block fs-10">{{ formValidations.email }}</div>
            </div>
            <div class="row flex-between-center">
              <div class="col-auto">
                <div class="form-check mb-0">
                  <input v-model="tempForm.acceptTerms" class="form-check-input" type="checkbox" id="basic-checkbox" checked="checked">
                  <label class="form-check-label mb-0" for="basic-checkbox">
                    I agree with the <a href="https://signum.money/tos" target="_blank">Terms of Service</a> and <a href="https://signum.money/privacy" target="_blank">Privacy Policy</a>
                  </label>
                  <div v-if="formValidations.acceptTerms" class="invalid-feedback d-block fs-10">{{ formValidations.acceptTerms }}</div>
                </div>
              </div>
            </div>
            <div class="mb-2 text-center">
              <button v-if="!loading" class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">Let's Go</button>
              <div v-if="loading" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div v-if="formValidations.apiError" class="invalid-feedback d-block fs-10">{{ formValidations.apiError }}</div>
            </div>
          </form>

          <router-link :to="{name: 'Login', params: {}}" class="fs-10">Go to Login</router-link>

        </div>
      </div>

      <div v-if="step == 2" class="card">
        <div class="card-body p-4 p-sm-5">
          <div class="row flex-between-center mb-2 text-center">
            <p>
              Please <span class="fw-bold">check your email</span> to confirm your signup.<br>
              We sent it to <span class="fw-bold">{{ form.email }}</span>
            </p>

            <img class="img-fluid" src="@/assets/signup/email_icon.png" />

            <router-link :to="{name: 'Signup', params: {}}" @click.prevent="startOver()" class="fs-10">Start over</router-link>

          </div>
        </div>
      </div>

      <div v-if="step == 3" class="card">
        <div class="card-body p-4 p-sm-5">
          <div class="row flex-between-center mb-2">
            <div class="col-12 text-start">
              <h5>Finish sign up for {{ tempForm.email }}</h5>
              <p class="mb-1">Choose your password.</p>
            </div>
          </div>
          <form @submit.prevent="submitSignupPasswordForm">
            <!-- Render this field so password managers can pick it up -->
            <div class="mb-3 d-none">
              <input v-model="tempForm.email" class="form-control" type="email" placeholder="Email address">
            </div>
            <div class="mb-3">
              <input v-model="form.password" class="form-control" type="password" placeholder="Password">
              <div v-if="formValidations.password" class="invalid-feedback d-block fs-10">{{ formValidations.password }}</div>
            </div>
            <div class="row flex-between-center">
              <div class="col-auto">
                <div class="form-check mb-0">
                  <input v-model="tempForm.acceptTerms" class="form-check-input" type="checkbox" id="basic-checkbox" checked="checked">
                  <label class="form-check-label mb-0" for="basic-checkbox">
                    I agree with the <a href="https://signum.money/tos" target="_blank">Terms of Service</a> and <a href="https://signum.money/privacy" target="_blank">Privacy Policy</a>
                  </label>
                  <div v-if="formValidations.acceptTerms" class="invalid-feedback d-block fs-10">{{ formValidations.acceptTerms }}</div>
                </div>
              </div>
            </div>
            <div class="mb-3 text-center">
              <button v-if="!loading" class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">Finish Signup</button>
              <div v-if="loading" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div v-if="formValidations.apiError" class="invalid-feedback d-block fs-10">{{ formValidations.apiError }}</div>
            </div>
          </form>

          <router-link :to="{name: 'Signup', params: {}}" @click.prevent="startOver()" class="fs-10">Start over</router-link>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      step: 1,
      form: {
        firstName: '',
        email: '',
        password: ''
      },
      tempForm: {
        email: '', // Used just to show the user for which email the signup is being done when coming back to complete the process
        acceptTerms: false
      },
      formValidations: {
        firstName: '',
        email: '',
        password: '',
        acceptTerms: '',
        apiError: ''
      }
    }
  },
  methods: {
    async submitSignupEmailForm () {
      this.loading = true

      const utms = this.$store.getters.getUTMs

      // Construct postData to send
      const postData = {
        ...this.form,
        emailConfirmUrlTemplate: process.env.VUE_APP_URL+'/#/signup?emailToken={signup_token}',
        utmMedium: utms?.medium,
        utmSource: utms?.source,
        utmCampaign: utms?.campaign,
        utmTerm: utms?.term,
        utmContent: utms?.content
      }

      // Validate form first
      if (!this.validateSignupEmailForm()) {
        this.loading = false
        return false
      }
      
      // Make an api call
      const url = process.env.VUE_APP_API_URL+'/users/signup/start_via_email'
      const rawResponse = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
      })

      if (rawResponse.status === 200) {
        // Show the email info
        this.step = 2

      } else {
        try {
          const response = await rawResponse.json()
          if (response.error && response.description) {
            this.formValidations.apiError = response.description+' ('+response.error+').'
          } else {
            this.formValidations.apiError = 'An unknown error occurred.'
          }
        } catch(e) {
          this.formValidations.apiError = 'An unknown error occurred.'
        }
      }

      this.loading = false
    },
    validateSignupEmailForm () {
      this.formValidations = {}

      this.form.firstName = this.form.firstName.replace(/\s/g, '')
      if (!this.form.firstName || this.form.firstName == '') {
        this.formValidations.firstName = 'Please enter your first name.'
        return false
      }

      this.form.email = this.form.email.replace(/\s/g, '')
      if (!this.form.email || this.form.email == '') {
        this.formValidations.email = 'Please enter your email.'
        return false
      }

      if (!this.validateEmail(this.form.email)) {
        this.formValidations.email = 'This is not a valid email address.'
        return false
      }

      if (!this.tempForm.acceptTerms) {
        this.formValidations.acceptTerms = 'Please read and accept our terms.'
        return false
      }

      return true
    },
    async submitSignupPasswordForm () {
      this.loading = true

      // Construct postData to send
      const postData = {
        password: this.form.password,
        token: this.$route.query.emailToken
      }

      // Validate form first
      if (!this.validateSignupPasswordForm()) {
        this.loading = false
        return false
      }
      
      // Make an api call
      const url = process.env.VUE_APP_API_URL+'/users/signup/finish'
      const rawResponse = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
      })

      if (rawResponse.status === 200) {
        const response = await rawResponse.json()

        // Validate the response
        if (response.accessTokenCurrent) {
          // Set a temporay userProfile which only contains the access_token so the getUserProfile action can later on fetch the profile using this token. It then sets the actual userProfile object.
          const userProfile = {
            ...response,
            access_token: response.accessTokenCurrent
          }

          this.$store.commit('setUserProfile', { userProfile: userProfile })

          // Fetch the actual userProfile we need further down the line
          await this.$store.dispatch('getUserProfile')

          const userProfileStore = this.$store.getters.getUserProfile

          // Identify the user so we can help them later on
          this.$posthog.identify(userProfileStore.id, {userId: userProfileStore.id, email: userProfileStore.email})

          // Redirect to the after signup page
          this.$router.push({ name: 'AfterSignup' })
        } else {
          this.formValidations.apiError = 'User account has been created but could not automatically login for you. Please login yourself with your credentials.'
        }
      } else {
        try {
          const response = await rawResponse.json()
          if (response.error && response.description) {
            this.formValidations.apiError = response.description+' ('+response.error+').'
          } else {
            this.formValidations.apiError = 'An unknown error occurred.'
          }
        } catch(e) {
          this.formValidations.apiError = 'An unknown error occurred.'
        }
      }

      this.loading = false
    },
    validateSignupPasswordForm () {
      this.formValidations = {}

      // Remove spaces
      this.form.password = this.form.password.replace(/\s/g, '')

      const passwordValid = this.validatePassword(this.form.password)
      if (passwordValid.length > 0) {
        this.formValidations.password = passwordValid
        return false
      }

      if (!this.tempForm.acceptTerms) {
        this.formValidations.acceptTerms = 'Please read and accept our terms.'
        return false
      }

      return true
    },
    validateEmail (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    validatePassword (password) {
      if (!password || password == '') {
        return "Choose a password."
      }

      if (password.length < 7) {
        return "Your password must be at least 7 characters."
      }

      const passwordStrength = this.getPasswordStrength(password)
      if ( passwordStrength < 3) {
        return "Your password is not very strong. It scores "+passwordStrength+" out of 4 points. Add capital LeTtErs, special characters $@#&! and/or numb3rs to strengthen it. It's for your own safety."
      }

      return ''
    },
    getPasswordStrength(password) {
      var strength = 0
      if (password.match(/[a-z]+/)) {
        strength += 1
      }
      if (password.match(/[A-Z]+/)) {
        strength += 1
      }
      if (password.match(/[0-9]+/)) {
        strength += 1
      }
      if (password.match(/[$@#&!]+/)) {
        strength += 1
      }

      return strength
    },
    async getEmailTokenData (emailToken) {
      if (!emailToken) return null

      // Construct postData to send
      const postData = {
        token: emailToken
      }

      // Make an api call
      const url = process.env.VUE_APP_API_URL+'/users/signup/continue_from_token'
      const rawResponse = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData),
      })

      if (rawResponse.status === 200) {
        const response = await rawResponse.json()
        return response

      } else {
        try {
          const response = await rawResponse.json()
          if (response.error && response.error != '') {
            this.formValidations.apiError = response.description+' ('+response.error+').'
          } else {
            this.formValidations.apiError = 'An unknown error occurred.'
          }
        } catch(e) {
          this.formValidations.apiError = 'An unknown error occurred.'
        }
      }

      return null
    },
    startOver () {
      // Because emptying objects is not reative for booleans (or sth. like that), we have to reset it manually here
      this.tempForm.acceptTerms = false

      // Empty all relevant obejcts
      this.form = {}
      this.tempForm = {}
      this.formValidations = {}

      this.step = 1
    }
  },
  async created () {
    // Redirect to Login when the user is logged in
    if (this.$store.getters.isUserLoggedIn) this.$router.push({ name: 'MyBots' })
  },
  async mounted () {
    // Check if the URL has the token, if so, go directly to step 2
    const emailToken = this.$route.query.emailToken
    if (emailToken && emailToken != '') {
      // Go to step 3, which is where the user picks the password
      this.step = 3

      const emailTokenData = await this.getEmailTokenData(emailToken)
      
      // Set the email so it can be picked up by password managers
      if (emailTokenData && emailTokenData.email) {
        this.tempForm.email = emailTokenData.email
      } else {
        this.formValidations.apiError = 'This email token has already expired. Please start your signup over again to get a fresh token.'
      }
    }
  }
}
</script>

<style scoped>

</style>