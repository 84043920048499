<template>
  <TheNavbar />

  <div class="mt-5 mb-5">

    <div class="row justify-content-center g-3">
      
      <div class="col-12 text-center">
        <p>A collection of strategies you can get started with 👀</p>
      </div>

      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
        <div class="card h-100">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Gaussian Channel Strategy</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            <img class="img-fluid rounded mx-auto mb-3" src="@/assets/strategies/gaussian_channel_chart.jpg" />

            <p>
              This strategy is private. You can get access to it by clicking on "Get Access" below.
            </p>

            <div class="text-center">
              <router-link :to="{name: 'GaussianChannelStrategy', params: {}}" class="btn btn-primary btn-sm" type="button" href="">
                Get Access
              </router-link>
            </div>
          </div>
        </div> <!-- card-->
      </div> <!-- col-->
      
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
        <div class="card h-100">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Hull Suite Strategy</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            <img class="img-fluid rounded mx-auto mb-3" src="@/assets/strategies/hull_suite_chart.jpg" />

            <p>
              This strategy is public, so you can use it without asking for access.
            </p>

            <div class="text-center">
              <a class="btn btn-primary btn-sm" type="button" href="https://www.tradingview.com/script/Q9OQye4C-Hull-Suite-Strategy/" target="_blank">
                Open Strategy <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-0"></span>
              </a>
            </div>
          </div>
        </div> <!-- card-->
      </div> <!-- col-->

      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
        <div class="card h-100">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Ichimoku TK Cross > EMA200</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            <img class="img-fluid rounded mx-auto mb-3" src="@/assets/strategies/ichimoku_tk_cross.jpg" />

            <p>
              This strategy is public, so you can use it without asking for access.
            </p>

            <div class="text-center">
              <a class="btn btn-primary btn-sm" type="button" href="https://www.tradingview.com/script/88TwdvFZ-Ichimoku-TK-Cross-EMA200-Crypto-Strategy/" target="_blank">
                Open Strategy <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-0"></span>
              </a>
            </div>
          </div>
        </div> <!-- card-->
      </div> <!-- col-->

      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
        <div class="card">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">How to use these strategies</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            <img class="img-fluid rounded" style="max-height: 40px;" src="@/assets/strategies/open_tv_strategy.jpg" />
            <p>
              Open the strategy you want to use.
            </p>

            <img class="img-fluid rounded" style="max-height: 40px;" src="@/assets/strategies/add_to_fav.jpg" />
            <p>
              Scroll down and add the strategy to your favorites.
            </p>

            <img class="img-fluid rounded mx-auto" src="@/assets/strategies/tv_indicators.jpg" />
            <p>
              <a href="https://www.tradingview.com/chart/" target="_blank">Go to TradingView <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-0"></span></a> and open the <span class="fw-bold">Indicators</span> menu.<br>
              Click on the strategies you want to add to your chart (click once per strategy).<br>
              <br>
              Now go to the <router-link :to="{name: 'MyBots', params: {}}" class="" href="">SIGNUM Bot</router-link> that you want to use (or create one) and follow the instructions there :)
            </p>


          </div>
        </div> <!-- card-->
      </div> <!-- col-->

      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
        <div class="card">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">AutoTrading Masterclass</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            <img class="img-fluid rounded mx-auto mb-3" src="@/assets/strategies/at_course_cover.jpg" />

            <p>
              Learn how to automate your Bitcoin & Crypto investments with algo trading strategies. Become a master in risk & portfolio management and trade automation.<br>
              Get access to the AutoTrading trading strategies, the community and 12 months free SIGNUM access.
            </p>

            <div class="text-center">
              <a class="btn btn-primary btn-sm" type="button" href="https://autotrading.vip" target="_blank">
                Learn More <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-0"></span>
              </a>
            </div>
          </div>
        </div> <!-- card-->
      </div> <!-- col-->
      
    </div>

  </div>
</template>

<script>
import TheNavbar from '@/components/TheNavbar'
export default {
  components: {
    TheNavbar
  },
  computed: {
    getSupportUrl () {
      return process.env.VUE_APP_SUPPORT_URL
    }
  },
  methods: {
    
  },
  async created () {
    if (!this.$store.getters.getUserBots) await this.$store.dispatch('getUserBots')
  },
  beforeMount () {
    // Redirect to Login when the user is not logged in
    if (!this.$store.getters.isUserLoggedIn) this.$router.push({ name: 'Login' })
  }
  
}
</script>