<template>
  <div class="row flex-center min-vh-100 py-6">
    <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 text-center">
      <img class="mb-3" src="logo_horizontal.png" alt="" height="50">
      <div class="card">
        <div class="card-body p-4 p-sm-5">
          <div class="row flex-between-center mb-2">
            <div class="col-auto">
              <h5>Log in</h5>
            </div>
            
          </div>
          <form @submit.prevent="login">
            <div class="mb-3">
              <input v-model="form.email" class="form-control" type="email" placeholder="Email address">
            </div>
            <div class="mb-3">
              <input v-model="form.password" class="form-control" type="password" placeholder="Password">
              <div class="text-start">
                <router-link :to="{name: 'ResetPassword', params: {}}" class="fs-10">Forgot Password?</router-link>
              </div>
              <div v-if="formValidations.password" class="invalid-feedback d-block fs-10">{{ formValidations.password }}</div>
            </div>

            <div class="mb-2 text-center">
              <button v-if="!loading" class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">Log in</button>
              <div v-if="loading" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </form>

          <router-link :to="{name: 'Signup', params: {}}" class="fs-10">Go to Sign Up</router-link>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      form: {
        email: '',
        password: ''
      },
      formValidations: {
        email: '',
        password: ''
      },
      formEmail: '',
      formPassword: ''
    }
  },
  methods: {
    async login () {
      this.loading = true
      this.formValidations = {}

      // Construct postData to send
      const postData = {
        email: this.form.email,
        password: this.form.password
      }

      // Make an api call
      const url = process.env.VUE_APP_API_URL+'/authenticate'
      const rawResponse = await fetch(url, {
        method: "POST",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData), // body data type must match "Content-Type" header
      })

      if (rawResponse.status === 200) {
        const response = await rawResponse.json() // parses JSON response into native JavaScript objects

        // Validate the response
        if (response.access_token) {
          // Set a temporay userProfile which only contains the access_token so the getUserProfile action can later on fetch the profile using this token. It then sets the actual userProfile object.
          const userProfile = {
            access_token: response.access_token
          }

          this.$store.commit('setUserProfile', { userProfile: userProfile })

          // Fetch the actual userProfile we need further down the line
          await this.$store.dispatch('getUserProfile')

          // Reset for form good measure
          this.form = {}

          // Prepare Posthog
          const userProfileStore = this.$store.getters.getUserProfile
          if (userProfileStore) {
            const dontTrackUserIds = [1, 2, 149]
            // Don't track some users in Posthog
            if (dontTrackUserIds.includes(userProfileStore?.id)) {
              console.log("Don't track in Posthog")
              this.$posthog.opt_out_capturing()
            } else {
              // Identify the user so we can help them later on
              this.$posthog.identify(userProfileStore.id, {userId: userProfileStore.id, email: userProfileStore.email})
            }
          }

          // Redirect to the bots page
          this.$router.push({ name: 'MyBots' })
        }
      } else {
        try {
          const response = await rawResponse.json() // parses JSON response into native JavaScript objects
          this.formValidations.password = response.error
        } catch(e) {
          this.formValidations.password = 'An unknown error occurred'
        }
      }

      this.loading = false
    }
  },
  async created () {
    // Redirect to Login when the user is logged in
    if (this.$store.getters.isUserLoggedIn) this.$router.push({ name: 'MyBots' })
  }
}
</script>

<style scoped>

</style>