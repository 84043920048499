<template>

  <div v-if="showTrialWillEndSoon" class="alert alert-info border-0 d-flex align-items-center" role="alert">
    <div class="bg-info me-3 icon-item"><span class="fas fa-info-circle text-white fs-6"></span></div>
    <p class="mb-0 flex-1">
      <span class="fw-bold">Your SIGNUM Trial will end on {{ getPrettyDateText(userProfile?.subscriptionTrialEndAt) }}</span><br>
      Until then you can fully enjoy SIGNUM with all its features 🥳<br>
      <br>
      <span class="fw-bold">Need a strategy to start with? Watch this video:</span> <a href="https://www.youtube.com/watch?v=C7r9-JVgURc" target="_blank">https://www.youtube.com/watch?v=C7r9-JVgURc</a>
      <br>
      <br>
      Subscribe before your trial ends to avoid trade interruptions:<br>
      <router-link :to="{name: 'Subscription', params: {}}" class="btn btn-primary btn-sm mt-1" type="button">
        Subscribe Now
      </router-link>
    </p>
  </div>

  <div v-if="userProfile?.subscriptionStatus == 'trial' && userProfile?.subscriptionTrialLeftSec <= 0" class="alert alert-danger border-0 d-flex align-items-center" role="alert">
    <div class="bg-danger me-3 icon-item"><span class="fas fa-times-circle text-white fs-6"></span></div>
    <p class="mb-0 flex-1">
      <span class="fw-bold">Your SIGNUM Trial has ENDED!</span><br>
      Your trial ended on {{ getPrettyDateText(userProfile.subscriptionTrialEndAt) }}.<br>
      SIGNUM is NOT processing your trading signals anymore.<br>
      <br>
      Subscribe to continue using SIGNUM:<br>
      <router-link :to="{name: 'Subscription', params: {}}" class="btn btn-primary btn-sm mt-1" type="button">
        Subscribe Now
      </router-link>
    </p>
  </div>

  <div v-if="userProfile?.subscriptionStatus == 'grace'" class="alert alert-warning border-0 d-flex align-items-center" role="alert">
    <div class="bg-warning me-3 icon-item"><span class="fas fa-exclamation-circle text-white fs-6"></span></div>
    <p class="mb-0 flex-1">
      <span class="fw-bold">Your SIGNUM Subscription has an ISSUE!</span><br>
      Maybe your payment method had an issue last time Stripe tried to charge it.<br>
      SIGNUM is STILL processing your trading signals 👍🏻<br>
      <br>
      Please login to the Stripe Customer Portal to fix it:<br>
      <a @click="goToStripeCustomerPortal()" target="_blank" class="btn btn-primary btn-sm mt-1" type="button">
        <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-2"></span>
        Stripe Customer Portal
      </a>
    </p>
  </div>

  <div v-if="userProfile?.subscriptionStatus == 'ending'" class="alert alert-warning border-0 d-flex align-items-center" role="alert">
    <div class="bg-warning me-3 icon-item"><span class="fas fa-exclamation-circle text-white fs-6"></span></div>
    <p class="mb-0 flex-1">
      <span class="fw-bold">Your SIGNUM Subscription is ending!</span><br>
      You canceled your subscription and it will end on {{ getPrettyDateText(userProfile.stripeSubscriptionEndAt) }}<br>
      SIGNUM is STILL processing your trading signals 👍🏻<br>
      <br>
      Please login to the Stripe Customer Portal if you want to reactivate it:<br>
      <a @click="goToStripeCustomerPortal()" target="_blank" class="btn btn-primary btn-sm mt-1" type="button">
        <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-2"></span>
        Stripe Customer Portal
      </a>
    </p>
  </div>

  <div v-if="userProfile?.subscriptionStatus == 'aborted'" class="alert alert-danger border-0 d-flex align-items-center" role="alert">
    <div class="bg-danger me-3 icon-item"><span class="fas fa-times-circle text-white fs-6"></span></div>
    <p class="mb-0 flex-1">
      <span class="fw-bold">Your SIGNUM Subscription has ENDED!</span><br>
      Stripe had issues charging your account and thus canceled your subscription.<br>
      SIGNUM is NOT processing your trading signals anymore.<br>
      <br>
      Subscribe again to continue using SIGNUM: <br>
      <router-link :to="{name: 'Subscription', params: {}}" class="btn btn-primary btn-sm mt-1" type="button">
        Subscribe Now
      </router-link>
    </p>
  </div>

  <div v-if="userProfile?.subscriptionStatus == 'canceled'" class="alert alert-danger border-0 d-flex align-items-center" role="alert">
    <div class="bg-danger me-3 icon-item"><span class="fas fa-times-circle text-white fs-6"></span></div>
    <p class="mb-0 flex-1">
      <span class="fw-bold">Your SIGNUM Subscription has ENDED!</span><br>
      SIGNUM is NOT processing your trading signals anymore.<br>
      <br>
      Subscribe again to continue using SIGNUM:<br>
      <router-link :to="{name: 'Subscription', params: {}}" class="btn btn-primary btn-sm mt-1" type="button">
        Subscribe Now
      </router-link>
    </p>
  </div>

</template>

<script>
export default {
  computed: {
    userProfile () {
      return this.$store.getters.getUserProfile
    },
    allUserBots () {
      return this.$store.getters.getUserBots
    },
    showTrialWillEndSoon () {
      return this.userProfile?.subscriptionStatus == 'trial' && this.userProfile?.subscriptionTrialLeftSec > 0 && this.userProfile?.subscriptionTrialLeftSec <= 1209600/2 // 7 days
    }
  },
  methods: {
    async goToStripeCustomerPortal () {
      const stripeCustomerPortalSession = await this.$store.dispatch('getUserStripePortalSession')
      window.open(stripeCustomerPortalSession.url,'_blank');
    },
    getSubscribeUrl () {
      const userEmail = this.userProfile?.email
      const userId = this.userProfile?.id

      const urlParams = {
        'prefilled_email': userEmail,
        'client_reference_id': userId
      }

      let stripeBasicSubUrl = process.env.VUE_APP_STRIPE_SUB_BASIC_URL+'?'+this.encodeQueryData(urlParams)
      if (this.userProfile?.isAutotradingUser) {
        stripeBasicSubUrl = process.env.VUE_APP_STRIPE_SUB_AUTOTRADING_URL+'?'+this.encodeQueryData(urlParams)
      }

      return stripeBasicSubUrl
    },
    getPrettyDateText (someString) {
      const timestamp = Date.parse(someString);
      const dateObj = new Date(timestamp)
      return dateObj.toLocaleString(undefined, {year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', hour12: false, minute:'2-digit', second:'2-digit'})
    },
    encodeQueryData(data) {
      const ret = [];
      for (let d in data)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      return ret.join('&');
    }
  },
  async mounted () {
    // Fetch the sripe portal session when needed (to be sure it's there)
    if (this.userProfile && (this.userProfile.subscriptionStatus == 'grace' || this.userProfile.subscriptionStatus == 'ending')) {
      if (!this.$store.getters.getUserStripePortalSession) await this.$store.dispatch('getUserStripePortalSession')
    }
  }
}
</script>

<style scoped>

</style>