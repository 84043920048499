<template>
  <div class="modal fade show" :id="modalId()" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content position-relative">
        <div class="position-absolute top-0 end-0 mt-2 me-2 z-1">
          <button @click.prevent="toggleModal(false)" class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"></button>
        </div>
        <div class="modal-body p-0">
          <div class="rounded-top-3 py-3 ps-4 pe-6 bg-body-tertiary">
            <h4 class="mb-1" id="modalExampleDemoLabel">{{ title }}</h4>
          </div>
          <div class="p-4 pb-3 text-center">
            <p>{{ message }}</p>
          </div>
        </div>
        <div class="modal-footer">
          <button v-if="!loading" @click="toggleModal(false)" class="btn btn-secondary" type="button">Cancel</button>
          <button v-if="!loading" @click="confirm()" class="btn btn-primary" type="button">Confirm</button>
          
          <div v-if="loading" class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal.js'

export default {
  props: {
    loading: {
      required: true,
      type: Boolean
    }
  },
  data () {
    return {
      title: '',
      message: '',
      callback: null,
      modal: null,
      randomHash: Math.random().toString(16).slice(2) // Generate a random number for the id of the modal
    }
  },
  methods: {
    modalId () {
      return 'modal-'+this.randomHash
    },
    setValues ({ title, message, callback }) {
      this.title = title
      this.message = message
      this.callback = callback
    },
    toggleModal (show) {
      if (show) {
        this.modal.show()
        return true
      }
      
      this.modal.hide()
      return true
    },
    async confirm () {
      await this.callback()
      this.modal.hide()
    }
  },
  mounted () {
    if (!this.modal) this.modal = new Modal('#'+this.modalId(), {})
  }
}
</script>

<style scoped>

</style>