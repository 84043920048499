import { createStore } from 'vuex'

export default createStore({
  state: {
    user: null, // Holds all user related data
    platformExchanges: null, // Holds all exchanges we support
    exchangeTradingPairs: {}, // Holds all trading pairs per exchangeId
    exchangeAssets: null, // This is overwritten EVERY TIME (on purpose)!
    getUserLogsPage: 1, // Init
    getUserLogsLimit: 10 // Init
  },
  getters: {
    isUserLoggedIn (state) {
      return (state.user?.profile?.access_token)? true : false
    },
    getUserProfile (state) {
      return state.user?.profile
    },
    getUserStripePortalSession (state) {
      return state.user?.stripeSession
    },
    getUserBots (state) {
      return state.user?.bots
    },
    getUserAssets (state) {
      return state.user?.assets
    },
    getUserBotAssets (state) {
      return state.user?.botAssets
    },
    getUserUsdPerExchange (state) {
      return state.user?.usdPerExchange
    },
    getUserLogs (state) {
      return state.user?.logs
    },
    getUserLogsPageLimit(state) {
      return { page: state.getUserLogsPage, limit: state.getUserLogsLimit }
    },
    getUserBotLogs: (state) => (botId) => {
      if (state.user?.botLogs && state.user.botLogs[botId]) {
        return state.user.botLogs[botId]
      }
      return []
    },
    getPlatformExchanges (state) {
      return state.platformExchanges
    },
    getUTMs (state) {
      return state.user?.utms
    }
  },
  actions: {
    getPlatformExchanges: async ({ commit, getters }) => {
      console.log('getPlatformExchanges')

      if (!getters.isUserLoggedIn) return null
      const userProfile = getters.getUserProfile
      
      // make an api call to our API to get the user token
      const url = process.env.VUE_APP_API_URL+'/exchanges'
      const rawResponse = await fetch(url, {
        method: "GET",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Authorization': 'Bearer '+userProfile.access_token,
          'Accept': 'application/json'
        }
      })
      
      if (rawResponse.status === 200) {
        const response = await rawResponse.json() // parses JSON response into native JavaScript objects
        commit('setPlatformExchanges', { exchanges: response })
      } else {
        // TODO: handle user token is now invalid for example and other states
      }
    },
    getExchangeTradingPairs: async ({ commit, getters }, { exchangeId, botId }) => {
      console.log('getExchangeTradingPairs')

      if (!exchangeId || !botId) return null

      if (!getters.isUserLoggedIn) return null
      const userProfile = getters.getUserProfile

      // make an api call to our API to get the user token
      const url = process.env.VUE_APP_API_URL+'/exchange/pairs/'+exchangeId+'?botId='+botId
      const rawResponse = await fetch(url, {
        method: "GET",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Authorization': 'Bearer '+userProfile.access_token,
          'Accept': 'application/json'
        }
      })
      
      if (rawResponse.status === 200) {
        const response = await rawResponse.json() // parses JSON response into native JavaScript objects

        // Remove all spaces and slashes
        const tradingPairs = response.map(pair => {
          pair.symbol = pair.symbol.replace('/', '') 
          pair.symbol = pair.symbol.replace(' ', '')
          return pair
        });

        commit('setExchangeTradingPairs', { exchangeId: exchangeId, exchangeTradingPairs: tradingPairs })
      } else {
        // TODO: handle user token is now invalid for example and other states
      }
    },
    getExchangeTradingPairsByApiKeys: async ({ commit, getters }, { exchangeId, apiKey, apiSecret, apiPassphrase }) => {
      console.log('getExchangeTradingPairs')

      // Do not validate the apiPassphrase because not all exchanges need it
      if (!exchangeId || !apiKey || !apiSecret) return null

      if (!getters.isUserLoggedIn) return null
      const userProfile = getters.getUserProfile

      let postData = {
        passphrase: apiPassphrase,
        apiKey: apiKey,
        secretKey: apiSecret.replace(/\\n/g, "\n"), // Fixing the JSON.stringify issue for Coinbase
        isTestNet: false
      }

      // make an api call to our API to get the user token
      const url = process.env.VUE_APP_API_URL+'/exchange/pairs/'+exchangeId
      const rawResponse = await fetch(url, {
        method: "POST",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Authorization': 'Bearer '+userProfile.access_token,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(postData), // body data type must match "Content-Type" header
      })
      
      if (rawResponse.status === 200) {
        const response = await rawResponse.json() // parses JSON response into native JavaScript objects

        // Remove all spaces and slashes
        const tradingPairs = response.map(pair => {
          pair.symbol = pair.symbol.replace('/', '') 
          pair.symbol = pair.symbol.replace(' ', '')
          return pair
        });

        commit('setExchangeTradingPairs', { exchangeId: exchangeId, exchangeTradingPairs: tradingPairs })
      } else {
        // TODO: handle user token is now invalid for example and other states
      }
    },
    getExchangeAssets: async ({ commit, getters }, { exchangeId, botId }) => {
      console.log('getExchangeAssets')

      if (!exchangeId || !botId) return null

      if (!getters.isUserLoggedIn) return null
      const userProfile = getters.getUserProfile

      // make an api call to our API to get the user token
      const url = process.env.VUE_APP_API_URL+'/exchange/assets/'+exchangeId+'?botId='+botId
      const rawResponse = await fetch(url, {
        method: "GET",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Authorization': 'Bearer '+userProfile.access_token,
          'Accept': 'application/json'
        }
      })
      
      if (rawResponse.status === 200) {
        const response = await rawResponse.json() // parses JSON response into native JavaScript objects
        commit('setExchangeAssets', { assets: response })
      } else {
        // TODO: handle user token is now invalid for example and other states

        // Because we need to fetch those every time, we unset it here if we got an error loading them
        commit('setExchangeAssets', { assets: [] }) // Set it ti [] because it means "0 assets there" instead of null which means "didn't check yet"
      }
    },
    getExchangeAssetsByApiKeys: async ({ commit, getters }, { exchangeId, apiKey, apiSecret, apiPassphrase }) => {
      console.log('getExchangeAssetsByApiKeys')

      // Do not validate the apiPassphrase because not all exchanges need it
      if (!exchangeId || !apiKey || !apiSecret) return null

      if (!getters.isUserLoggedIn) return null
      const userProfile = getters.getUserProfile

      let postData = {
        passphrase: apiPassphrase,
        apiKey: apiKey,
        secretKey: apiSecret.replace(/\\n/g, "\n"), // Fixing the JSON.stringify issue for Coinbase
        isTestNet: false
      }

      // make an api call to our API to get the user token
      const url = process.env.VUE_APP_API_URL+'/exchange/assets/'+exchangeId
      const rawResponse = await fetch(url, {
        method: "POST",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Authorization': 'Bearer '+userProfile.access_token,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(postData), // body data type must match "Content-Type" header
      })
      
      if (rawResponse.status === 200) {
        const response = await rawResponse.json() // parses JSON response into native JavaScript objects
        commit('setExchangeAssets', { assets: response })
      } else {
        // TODO: handle user token is now invalid for example and other states

        // Because we need to fetch those every time, we unset it here if we got an error loading them
        commit('setExchangeAssets', { assets: [] }) // Set it ti [] because it means "0 assets there" instead of null which means "didn't check yet"
      }
    },
    getUserBots: async ({ commit, getters }) => {
      console.log('getUserBots')

      if (!getters.isUserLoggedIn) return null
      const userProfile = getters.getUserProfile

      // make an api call to our API to get the user token
      const url = process.env.VUE_APP_API_URL+'/bots'
      const rawResponse = await fetch(url, {
        method: "GET",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Authorization': 'Bearer '+userProfile.access_token,
          'Accept': 'application/json'
        }
      })
      
      if (rawResponse.status === 200) {
        const response = await rawResponse.json() // parses JSON response into native JavaScript objects
        commit('setUserBots', { userBots: response })
      } else {
        // TODO: handle user token is now invalid for example and other states
      }
    },
    getUserLogs: async ({ commit, getters }, {page, limit}) => {
      console.log('getUserLogs')

      if (!getters.isUserLoggedIn) return null
      const userProfile = getters.getUserProfile

      // Fetch page and limit from the store
      const userLogsPageLimit = getters.getUserLogsPageLimit
      if (!page) page = userLogsPageLimit.page
      if (!limit) limit = userLogsPageLimit.limit

      // Make API call
      const url = process.env.VUE_APP_API_URL+'/auditlog?page='+page+'&limit='+limit
      const rawResponse = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          'Authorization': 'Bearer '+userProfile.access_token,
          'Accept': 'application/json'
        }
      })
      
      // Check status codes, handle errors and process response
      if (rawResponse.status === 200) {
        const response = await rawResponse.json()
        commit('setUserLogs', { logs: response })
      } else {
        // TODO: Handle errors
      }
    },
    getUserBotLogs: async ({ commit, getters }, { botId, page, limit }) => {
      console.log('getUserBotLogs')

      if (!getters.isUserLoggedIn) return null
      const userProfile = getters.getUserProfile

      if (!page) page = 1
      if (!limit) limit = 100

      // Make API call
      const url = process.env.VUE_APP_API_URL+'/auditlog/bot/'+botId+'?page='+page+'&limit='+limit
      const rawResponse = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          'Authorization': 'Bearer '+userProfile.access_token,
          'Accept': 'application/json'
        }
      })
      
      // Check status codes, handle errors and process response
      if (rawResponse.status === 200) {
        const response = await rawResponse.json()
        commit('setUserBotLogs', { botId: botId, logs: response })
      } else {
        // TODO: Handle errors
      }
    },
    createBot: async ({ dispatch, getters }, bot) => {
      console.log('createBot', bot)

      if (!bot) return null

      if (!getters.isUserLoggedIn) return null
      const userProfile = getters.getUserProfile

      const postData = bot

      // make an api call to our API to get the user token
      const url = process.env.VUE_APP_API_URL+'/bots'
      const rawResponse = await fetch(url, {
        method: "POST",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Authorization': 'Bearer '+userProfile.access_token,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(postData), // body data type must match "Content-Type" header
      })

      if (rawResponse.status === 200) {
        const response = await rawResponse.json() // parses JSON response into native JavaScript objects
        
        // TODO: it's better to fetch only that 1 bot and add it to the state manually
        // now fetch all user bots fresh
        await dispatch('getUserBots')

        return response

      } else {
        // TODO: handle user token is now invalid for example and other states
      }

      return {}
    },
    updateBot: async ({ dispatch, getters }, bot) => {
      console.log('updateBot', bot)

      if (!getters.isUserLoggedIn) return null
      const userProfile = getters.getUserProfile

      const postData = bot

      // make an api call to our API to get the user token
      const url = process.env.VUE_APP_API_URL+'/bots/' + bot.id
      const rawResponse = await fetch(url, {
        method: "PATCH",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Authorization': 'Bearer '+userProfile.access_token,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(postData), // body data type must match "Content-Type" header
      })

      if (rawResponse.status === 200) {
        // const response = await rawResponse.json() // parses JSON response into native JavaScript objects
        
        // TODO: it's better to fetch only that 1 bot and add it to the state manually
        // now fetch all user bots fresh
        await dispatch('getUserBots')

      } else {
        // TODO: handle user token is now invalid for example and other states
      }
    },
    deleteBot: async ({ dispatch, getters }, bot) => {
      console.log('deleteBot', bot)

      if (!getters.isUserLoggedIn) return null
      const userProfile = getters.getUserProfile

      // make an api call to our API to get the user token
      const url = process.env.VUE_APP_API_URL+'/bots/' + bot.id
      const rawResponse = await fetch(url, {
        method: "DELETE",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Authorization': 'Bearer '+userProfile.access_token,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })

      if (rawResponse.status === 200) {
        // TODO: it's better to fetch only that 1 bot and add it to the state manually
        // now fetch all user bots fresh
        await dispatch('getUserBots')

        // TODO: Loading page = 1 and limit = 10 here is basically overruling whatever the user had loaded already in the LogsList component. Those value are not in sync anymore. Not the best.
        // TODO: Maybe it's better to have getUserLogsPage and getUserLogsLimit in the store and use those accross the whole app.
        const userLogsPageLimit = getters.getUserLogsPageLimit
        await dispatch('getUserLogs', {page: userLogsPageLimit.page, limit: userLogsPageLimit.limit})

      } else {
        // TODO: handle user token is now invalid for example and other states
      }

    },
    getAllUserAssets: async ({ dispatch, commit, getters }) => {
      console.log('getAllUserAssets')

      // Fetch userBots if not yet there
      if (!getters.getUserBots) await dispatch('getUserBots')

      if (!getters.isUserLoggedIn) return null
      const userProfile = getters.getUserProfile

      // If the user has no bots, then set the user assets to an empty []
      if (!getters.getUserBots || getters.getUserBots.length <= 0) {
        commit('setAllUserAssets', { allUserAssets: [] })
        commit('setUserBotAssets', { userBotAssets: [] })
        commit('setUserUsdPerExchange', { userUsdPerExchange: [] })
        return false
      }
      
      let allUserBotAssets = [] // Assets per bot in USD
      let allUserAssetsPerApiKey = [] // Assets per apiKey
      let allUserAssetsPerExchange = [] // Assets per exchange id
      let allUserAssets = [] // Final result containing the USD values

      for (let bot of getters.getUserBots) {
        // TODO: Remove this once we removed all my test bots which have invalid API Keys
        if(bot.exchangeConfig.apiKey.length < 10) continue

        // Fetch data
        const url = process.env.VUE_APP_API_URL+'/exchange/assets/'+bot.exchangeId+'?botId='+bot.id // TODO: Ask Adrian to create an endpoint, which returns all assets instead of me having to do a loop here (what is faster?)
        const rawResponse = await fetch(url, {
          method: "GET",
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            'Authorization': 'Bearer '+userProfile.access_token,
            'Accept': 'application/json'
          }
        })
        if (rawResponse.status === 200) {
          const response = await rawResponse.json() // parses JSON response into native JavaScript objects

          // De-duplicate when apiKey/exchangeAccount is used multiple times (which should not be allowed but still can happen)
          for (let asset of response) {
            const apiKey = bot.exchangeConfig.apiKey
            const uniqueKey = apiKey+'_'+asset.coin

            // Assets per api key (to de-duplicate when the same API key is used twice)
            // TODO: Also try to filter out cases where the same account is connected but via a different API key
            allUserAssetsPerApiKey[uniqueKey] = {
              coin: asset.coin,
              balance: asset.balance
            }

            // Assets per bot
            allUserBotAssets.push({
              botId: bot.id,
              coin: asset.coin,
              balance: asset.balance
            })

            // Assets per exchange
            if (!allUserAssetsPerExchange[bot.exchangeId]) allUserAssetsPerExchange[bot.exchangeId] = []
            allUserAssetsPerExchange[bot.exchangeId].push({
              exchangeId: bot.exchangeId,
              coin: asset.coin,
              balance: asset.balance
            })
          }

        } else {
          // TODO: handle user token is now invalid for example and other states
        }
      }

      // Cannot check if allUserAssetsPerExchangeAccount is empty or not because it's an associative array (shrug)

      // Group per asset. Results in 2D Object with all occurrences of 1 asset inside the same index
      const allUserAssetsGrouped = Object.groupBy(Object.values(allUserAssetsPerApiKey), ({ coin }) => coin);

      // Sum up the balances to get the total balance per asset
      for (const index in allUserAssetsGrouped) {
        allUserAssets[index] = {
          coin: index,
          balance: Number(0)
        }

        for (const index2 in allUserAssetsGrouped[index]) {
          const coin = allUserAssetsGrouped[index][index2]
          allUserAssets[index].balance += Number(coin.balance)
        }
      }

      // Create a comma separated list of all assets
      const onlyDistinctAssets = []
      for (const index in allUserAssets) {
        const coin = allUserAssets[index].coin
        onlyDistinctAssets.push(coin)

        // Workaround: Some coins collide with other assets or national currencies - fetch the alternatives and match them later on properly
        if (coin == 'MNT') onlyDistinctAssets.push('MANTLE')
        if (coin == 'BOB') onlyDistinctAssets.push('BOBT')
        // if (coin == 'TON') onlyDistinctAssets.push('TONCOIN')
      }
      // Convert the asset list to a CSV
      const assetsCsv = onlyDistinctAssets.join(',')

      if (!assetsCsv) {
        console.log('No assets to load prices for.')
        commit('setAllUserAssets', { allUserAssets: [] })
        commit('setUserBotAssets', { userBotAssets: [] })
        commit('setUserUsdPerExchange', { userUsdPerExchange: [] })
        return false
      }

      // TODO: Change the API we use to fetch those prices!!! The one from cryptocompare has a cost once we launch paid plans for SIGNUM!
      // Fetch USD prices for all distinct assets (crypto & fiat)
      let assetsUsdPrices = null
      const url = 'https://min-api.cryptocompare.com/data/pricemulti?fsyms='+assetsCsv+'&tsyms=USD' // https://min-api.cryptocompare.com/documentation?key=Price&cat=multipleSymbolsPriceEndpoint
      const rawResponse = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
      })
      if (rawResponse.status === 200) {
        assetsUsdPrices = await rawResponse.json()
      } else {
        console.log("Could not fetch USD prices from the prices API.")
      }

      if (!assetsUsdPrices) {
        console.log("Prices api did not return prices for given user assets")
        commit('setAllUserAssets', { allUserAssets: [] })
        commit('setUserBotAssets', { userBotAssets: [] })
        commit('setUserUsdPerExchange', { userUsdPerExchange: [] })
        return false
      }

      // Handle user assets - add USD value for each coin
      for (const index in allUserAssets) {
        let coin = allUserAssets[index].coin
        const balance = Number(allUserAssets[index].balance)
        
        // Workaround: Some coins collide with other assets or national currencies - fetch the alternatives and match them later on properly
        if (coin == 'MNT') coin = 'MANTLE'
        if (coin == 'BOB') coin = 'BOBT'
        // if (coin == 'TON') coin = 'TONCOIN'

        if (assetsUsdPrices[coin]) {
          const assetPriceInUsd = Number(assetsUsdPrices[coin].USD)
          allUserAssets[index].balanceUSD = assetPriceInUsd * balance
        } else {
          console.log("User assets: Did not find USD price for "+coin)
        }
      }

      // Transform to an array of objects
      allUserAssets = Object.values(allUserAssets)

      // Sort it based on the USD value DESC
      allUserAssets.sort((a,b) => (a.balanceUSD < b.balanceUSD) ? 1 : ((b.balanceUSD < a.balanceUSD) ? -1 : 0))
      
      // Set data
      commit('setAllUserAssets', { allUserAssets: allUserAssets })

      // Handle bot assets - add USD value for each coin
      for (const assetIndex in allUserBotAssets) {
        const asset = allUserBotAssets[assetIndex]
        let coin = asset.coin
        const balance = Number(asset.balance)

        // Workaround: Some coins collide with other assets or national currencies - fetch the alternatives and match them later on properly
        if (coin == 'MNT') coin = 'MANTLE'
        if (coin == 'BOB') coin = 'BOBT'
        // if (coin == 'TON') coin = 'TONCOIN'
        
        if (assetsUsdPrices[coin]) {
          const assetPriceInUsd = Number(assetsUsdPrices[coin].USD)

          allUserBotAssets[assetIndex].balanceUSD = assetPriceInUsd * balance
        } else {
          console.log("Bot assets: Did not find USD price for "+coin)
        }
      }

      // Transform to an array of objects
      // allUserBotAssets = Object.values(allUserBotAssets)

      // Sort it based on the USD value DESC
      allUserAssets.sort((a,b) => (a.botId < b.botId) ? 1 : ((b.botId < a.botId) ? -1 : 0))
      
      // Set data
      commit('setUserBotAssets', { userBotAssets: allUserBotAssets })

      // Handle exchange assets - add and sum up the USD value per exchange
      let userUsdPerExchange = []
      // Add USD values to all assets which are grouped per exchange
      for (const exchangeId in allUserAssetsPerExchange) {
        let totalUSD = 0

        for (const assetIndex in allUserAssetsPerExchange[exchangeId]) {
          const asset = allUserAssetsPerExchange[exchangeId][assetIndex]
          let coin = asset.coin
          const balance = Number(asset.balance)

          // Workaround: Some coins collide with other assets or national currencies - fetch the alternatives and match them later on properly
          if (coin == 'MNT') coin = 'MANTLE'
          if (coin == 'BOB') coin = 'BOBT'
          // if (coin == 'TON') coin = 'TONCOIN'
          
          if (assetsUsdPrices[coin]) {
            const assetPriceInUsd = Number(assetsUsdPrices[coin].USD)

            // Sum up the USD value per exchange
            totalUSD += assetPriceInUsd * balance

          } else {
            console.log("Exchange assets: Did not find USD price for "+coin)
          }
        }
        userUsdPerExchange.push({
          exchangeId: exchangeId,
          balanceUSD: totalUSD
        })
      }

      // Sort it based on the USD value DESC
      userUsdPerExchange.sort((a,b) => (a.balanceUSD < b.balanceUSD) ? 1 : ((b.balanceUSD < a.balanceUSD) ? -1 : 0))

      // Set data
      commit('setUserUsdPerExchange', { userUsdPerExchange: userUsdPerExchange })
    },
    getUserProfile: async ({ commit, getters }) => {
      console.log('fetchUserProfile')

      if (!getters.isUserLoggedIn) return null
      const userProfile = getters.getUserProfile

      // Make API call
      const url = process.env.VUE_APP_API_URL+'/users/me'
      const rawResponse = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          'Authorization': 'Bearer '+userProfile.access_token,
          'Accept': 'application/json'
        }
      })
      
      // Check status codes, handle errors and process response
      if (rawResponse.status === 200) {
        const response = await rawResponse.json()
        
        // For backwards compatibilty
        response.access_token = response.accessTokenCurrent

        commit('setUserProfile', { userProfile: response })
      } else {
        // TODO: Handle errors
      }
    },
    getUserStripePortalSession: async ({ commit, getters }) => {
      console.log('getUserStripePortalSession')

      if (!getters.isUserLoggedIn) return null
      const userProfile = getters.getUserProfile

      // Don't fetch it again if it's still valid
      const timeSecondsNow = Math.floor(Date.now() / 1000)
      const stripeSession = getters.getUserStripePortalSession
      if (stripeSession && stripeSession.created + 60*10 >= timeSecondsNow) {
        return stripeSession
      }

      // Make API call
      const url = process.env.VUE_APP_API_URL+'/users/me/stripe/portal_session'
      const rawResponse = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          'Authorization': 'Bearer '+userProfile.access_token,
          'Accept': 'application/json'
        }
      })
      
      // Check status codes, handle errors and process response
      if (rawResponse.status === 200) {
        const response = await rawResponse.json()

        commit('setUserStripePortalSession', { userStripeSession: response })
        return response

      } else {
        // TODO: Handle errors
      }

      return null
    },
    updateUserProfile: async ({ commit, getters }, newUserProfile) => {
      console.log('updateUserProfile')

      if (!getters.isUserLoggedIn) return null
      const userProfile = getters.getUserProfile

      const postData = newUserProfile

      // make an api call to our API to get the user token
      const url = process.env.VUE_APP_API_URL+'/users/me'
      const rawResponse = await fetch(url, {
        method: "PATCH",
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Authorization': 'Bearer '+userProfile.access_token,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(postData), // body data type must match "Content-Type" header
      })

      if (rawResponse.status === 200) {
        const response = await rawResponse.json() // parses JSON response into native JavaScript objects
        
        await commit('setUserProfile', { userProfile: response})

      } else {
        // TODO: handle user token is now invalid for example and other states
      }
    },
    logout: ({ commit }) => {
      commit('resetUser')
    }
  },
  mutations: {
    setPlatformExchanges (state, { exchanges }) {
      state.platformExchanges = exchanges
    },
    setExchangeTradingPairs (state, { exchangeId, exchangeTradingPairs }) {
      state.exchangeTradingPairs[exchangeId] = exchangeTradingPairs
    },
    setExchangeAssets (state, { assets }) {
      state.exchangeAssets = assets
    },
    setUserProfile (state, { userProfile }) {
      if (!state.user) {
        state.user = {}
      }

      // For backwards compatibility until we get rid of the access_token field
      if (!userProfile.access_token || userProfile.access_token == '') {
        userProfile.access_token = userProfile.accessTokenCurrent
      }

      state.user.profile = userProfile
      localStorage.setItem('userProfile', JSON.stringify(userProfile))
    },
    setUserStripePortalSession (state, { userStripeSession }) {
      if (!state.user) {
        state.user = {}
      }

      state.user.stripeSession = userStripeSession
    },
    resetUser (state) {
      state.user = null
      localStorage.removeItem('userProfile')
      localStorage.removeItem('userUtms')
    },
    setUserBots (state, { userBots }) {
      state.user.bots = userBots
    },
    setAllUserAssets (state, { allUserAssets }) {
      state.user.assets = allUserAssets
    },
    setUserBotAssets (state, { userBotAssets }) {
      state.user.botAssets = userBotAssets
    },
    setUserUsdPerExchange (state, { userUsdPerExchange }) {
      state.user.usdPerExchange = userUsdPerExchange
    },
    setUserBotLogs (state, { botId, logs }) {
      if (!state.user.botLogs) {
        state.user.botLogs = {}
      }
      state.user.botLogs[botId] = logs
    },
    setUserLogs (state, { logs }) {
      if (!state.user.logs) {
        state.user.logs = {}
      }
      state.user.logs = logs
    },
    setUserLogsPageLimit (state, { page, limit }) {
      state.getUserLogsPage = page
      state.getUserLogsLimit = limit
    },
    setUTMs (state, { utms }) {
      if (!state.user) {
        state.user = {}
      }

      // Set them fresh if they are not empty
      if (utms.medium || utms.source || utms.campaign || utms.term || utms.content) {
        state.user.utms = utms
        localStorage.setItem('userUtms', JSON.stringify(utms))
      } else { // Otherwise load them from local storage to always have them available
        utms = JSON.parse(localStorage.getItem("userUtms"));
        state.user.utms = utms
      }
    }
  }
})