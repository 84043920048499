<template>

  <div v-if="platformExchanges" class="table-responsive scrollbar">
    <table class="table table-hover table-sm table-striped fs-10 mb-0 overflow-hidden">
      <thead class="bg-200">
        <tr>
          <th class="text-900 sort pe-1 align-middle white-space-nowrap" data-sort="order">Name</th>
          <th class="text-900 sort pe-1 align-middle white-space-nowrap pe-7" data-sort="date">Trading Pair</th>
          <th class="text-900 sort pe-1 align-middle white-space-nowrap" data-sort="address" style="min-width: 12.5rem;">Exchange</th>
          <th class="text-900 sort pe-1 align-middle white-space-nowrap text-center" data-sort="status">Status</th>
          <th class="no-sort"></th>
        </tr>
      </thead>
      <tbody class="list" id="table-orders-body">
        <tr v-for="bot in bots" :key="bot.id" class="btn-reveal-trigger">
          <td class="order py-2 align-middle white-space-nowrap text-truncate">
            <router-link :to="{name: 'EditBot', params: {id: bot.id}}"><span class="fw-bold">{{ bot.title }}</span></router-link>
          </td>
          <td class="date py-2 align-middle">
            <span class="fw-bold">{{ bot.baseCoin }}/{{ bot.quoteCoin }}</span>
          </td>
          <td class="address py-2 align-middle white-space-nowrap text-truncate">
            <img class="img-fluid rounded mx-auto" style="height: 30px" :src="`/exchanges/${bot.exchangeId.toLowerCase()}.png`" />&nbsp;
            <span class="fw-bold">{{ getExchangeById(bot.exchangeId).name }}</span> <span lass="text-muted fw-light fs-1">({{ truncateText(bot.exchangeConfig.apiKey) }})</span>
          </td>
          <td class="status py-2 align-middle text-center fs-9 white-space-nowrap text-truncate">
            <span v-if="bot.enabled" class="badge badge rounded-pill d-block badge-subtle-success">Active<span class="ms-1 fas fa-check" data-fa-transform="shrink-2"></span></span>
            <span v-if="!bot.enabled" class="badge badge rounded-pill d-block badge-subtle-secondary">Paused<span class="ms-1 fas fa-pause" data-fa-transform="shrink-2"></span></span>
          </td>
          <td class="py-2 align-middle white-space-nowrap text-truncate text-end">
            <div class="dropdown font-sans-serif position-static">
              <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal" type="button" :id="'dropdown_bot_id_'+bot.id" data-bs-toggle="dropdown" data-boundary="viewport" aria-haspopup="true" aria-expanded="false">
                <span class="fas fa-ellipsis-h fs-10"></span>
              </button>
              <div class="dropdown-menu dropdown-menu-end border py-0" :aria-labelledby="'dropdown_bot_id_'+bot.id">
                <div class="py-2">
                  <router-link :to="{name: 'EditBot', params: {id: bot.id}}" class="dropdown-item">Edit</router-link>
                  <div class="dropdown-divider"></div>
                  <a v-if="!bot.enabled" @click.prevent="toggleBot(bot, !bot.enabled)" class="dropdown-item text-success" href="#!">Activate</a>
                  <a v-if="bot.enabled" @click.prevent="toggleBot(bot, !bot.enabled)" class="dropdown-item text-warning" href="#!">Pause</a>
                
                  <div v-if="!bot.enabled" class="dropdown-divider"></div>
                  <a v-if="!bot.enabled" @click.prevent="deleteBot(bot)" class="dropdown-item text-danger">Delete</a>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <SimpleModal ref="modalRef" :loading="loading" />

</template>

<script>
import SimpleModal from '@/components/SimpleModal'
import Dropdown from 'bootstrap/js/dist/dropdown.js'

export default {
  props: {
    bots: {
      required: true,
      type: Array
    }
  },
  components: {
    SimpleModal
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    platformExchanges () {
      return this.$store.state.platformExchanges
    },
  },
  methods: {
    getExchangeById (id) {
      return this.$store.state.platformExchanges.find(elem => elem.id === id)
    },
    async toggleBot (bot, enabled) {
      let newBot = {
        enabled: enabled,
        id: bot.id
      }

      this.$refs.modalRef.setValues({
        title: (enabled)? 'Activate Bot?' : 'Pause Bot?',
        message: (enabled)? 'Are you sure you want to activate your "'+bot.title+'" Bot?' : 'Are you sure you want to pause your "'+bot.title+'" Bot?',
        callback: async () => {
          this.loading = true
          await this.$store.dispatch('updateBot', newBot)
          this.loading = false
          // TODO: Handle errors and show them somewhere!!!
        }
      })

      this.$refs.modalRef.toggleModal(true)
    },
    async deleteBot (bot) {
      this.$refs.modalRef.setValues({
        title: 'Delete Bot?',
        message: 'Are you sure you want to delete your "'+bot.title+'" Bot? This action cannot be undone!',
        callback: async () => {
          this.loading = true
          await await this.$store.dispatch('deleteBot', bot)
          this.loading = false
          // TODO: Handle errors and show them somewhere!!!
        }
      })

      this.$refs.modalRef.toggleModal(true)
    },
    truncateText(text) {
      let truncatedText = text.substring(0, 20)
      if (truncatedText.length < text.length) truncatedText = truncatedText + "..."
      return truncatedText
    }
  },
  async created () {
    // TODO: Get's called for each BotList we invoke - atm. it's 2 times for active and paused Bots
    if (!this.$store.state.platformExchanges) await this.$store.dispatch('getPlatformExchanges')
  },
  mounted () {
    // Initialize all dropdowns
    var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
    dropdownElementList.map(function (dropdownToggleEl) {
      return new Dropdown(dropdownToggleEl)
    })
  }
}
</script>

<style scoped>
.dropdown-menu {
  z-index: 9999;
}
</style>