<template>
  <nav class="navbar navbar-light navbar-glass navbar-top navbar-expand-lg navbar-glass-shadow">
    <button class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3" type="button" data-bs-toggle="collapse" :data-bs-target="'#'+navBarId()">
      <span class="navbar-toggle-icon"><span class="toggle-line"></span></span>
    </button>
    <a class="navbar-brand me-1 me-sm-3" href="">
      <div class="d-flex align-items-center">
        

        <router-link :to="{name: 'MyBots', params: {}}" class="link-underline link-underline-opacity-0">
          <img class="me-2" src="logo_horizontal.png" alt="" height="40">
          <!-- <span class="font-sans-serif text-primary">SIGNUM</span> -->
        </router-link>
      </div>
    </a>

    <div class="collapse navbar-collapse scrollbar" :id="navBarId()">
      <ul class="navbar-nav" data-top-nav-dropdowns="data-top-nav-dropdowns">
        <li v-if="allUserBots?.length > 0" class="nav-item">
          <router-link :to="{name: 'Dashboard', params: {}}" class="nav-link" href="">Dashboard</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'MyBots', params: {}}" class="nav-link" href="">My Bots</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'Strategies', params: {}}" class="nav-link" href="">Strategies</router-link>
        </li>
      </ul>
    </div>

    <ul class="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
      <li class="nav-item dropdown">
        <a class="nav-link pe-0 ps-2" id="navbarDropdownUser" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div class="avatar avatar-xl">
            <!-- <img class="rounded-circle" src="@/assets/theme/img/team/3-thumb.png" alt=""> -->
            <span class="fas fa-user-circle fs-5"></span>
          </div>
        </a>
        <div class="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end py-0" aria-labelledby="navbarDropdownUser">
          <div v-if="allUserBots?.length > 0" class="bg-white dark__bg-1000 rounded-2 py-2 pb-0">
            <router-link :to="{name: 'Dashboard'}" class="dropdown-item">My Dashboard</router-link>
          </div>
          <div class="bg-white dark__bg-1000 rounded-2 py-2 pb-0">
            <router-link :to="{name: 'MyBots'}" class="dropdown-item">My Bots</router-link>
          </div>
          <div class="bg-white dark__bg-1000 rounded-2 py-2 pb-0">
            <router-link :to="{name: 'Subscription'}" class="dropdown-item">My Subscription</router-link>
          </div>
          <div class="bg-white dark__bg-1000 rounded-2 py-2 pb-0">
            <router-link :to="{name: 'Profile'}" class="dropdown-item">Change Password</router-link>
          </div>
          <div class="bg-white dark__bg-1000 rounded-2 py-2 pb-0">
            <a class="dropdown-item" :href="getSupportUrl()" target="_blank">Get Support (Telegram) <span class="fas fa-external-link-alt" data-fa-transform="shrink-3 down-0"></span></a>
          </div>
          <div class="bg-white dark__bg-1000 rounded-2 py-2">
            <a @click.prevent="logout()" class="dropdown-item" href="#">Logout ({{ user?.profile?.email }})</a>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import Collapse from 'bootstrap/js/dist/collapse.js'

export default {
  computed: {
    user () {
      return this.$store.state.user
    },
    allUserBots () {
      return this.$store.getters.getUserBots
    },
  },
  data () {
    return {
      randomHash: Math.random().toString(16).slice(2) // Generate a random number for the id of the modal
    }
  },
  methods: {
    navBarId () {
      return 'navbar-id-'+this.randomHash
    },
    getSupportUrl () {
      return process.env.VUE_APP_SUPPORT_URL
    },
    logout () {
      this.$store.dispatch('logout')
      
      // Reset Posthog identifiers
      this.$posthog.reset()

      // Redirect
      this.$router.push({ name: 'Login' })
    }
  },
  mounted () {
    new Collapse('#'+this.navBarId(), {
      toggle: false
    })
  }
}
</script>

<style scoped>

</style>