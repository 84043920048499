<template>

  <div v-if="userProfile" class="mt-5 mb-5">

    <div class="row justify-content-center mb-3">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-10 col-xxl-8 text-center">
        <img class="mb-3" src="logo_horizontal.png" alt="" height="50">

        <div class="card mb-4">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Welcome to SIGNUM 🥳</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            
            <div class="mb-3" data-tf-live="01J545G3FKMPZ1197ZZX9R5DGS" data-tf-inline-on-mobile data-tf-auto-resize :data-tf-hidden="'email='+userProfile?.email"></div>
            
            <router-link :to="{name: 'MyBots'}" class="fs-10" type="button">
              Skip
            </router-link>

          </div>
          
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import TheNavbar from '@/components/TheNavbar'

export default {
  components: {
    // TheNavbar
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    userProfile () {
      return this.$store.getters.getUserProfile
    }
  },
  methods: {
    
  },
  mounted () {
    let typeScriptEmbedScriptTag = document.createElement('script')
    typeScriptEmbedScriptTag.setAttribute('src', 'https://embed.typeform.com/next/embed.js')
    document.head.appendChild(typeScriptEmbedScriptTag)

    window.addEventListener('message', function(event){
      if(event.data.type == 'form-submit') {
        // this.$router.push({ name: 'MyBots' }) // TODO: Throws an error because it's out of scope
        window.location.href = '/#/bots' // TODO: Should be routed via route name
      }
    }, false);
  },
  beforeMount () {
    // Redirect to Login when the user is not logged in
    if (!this.$store.getters.isUserLoggedIn) this.$router.push({ name: 'Login' })
  }
  
}
</script>

<style scoped>

</style>