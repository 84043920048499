<template>
  <TheNavbar />

  <div v-if="userProfile" class="mt-5 mb-5">

    <div class="row justify-content-center mb-3">
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">
        <div class="card mb-4">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Change password</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            <div v-if="!passwordFormSuccess">
              <p>Choose a new and secure password. Your password manager should pop-up and ask you to save this new password - do it :)</p>

              <form @submit.prevent="" class="needs-validation" novalidate>
                <div class="row mb-3 justify-content-center visually-hidden">
                  <div class="col">
                    <div class="form-floating">
                      <input v-model="userProfile.email" class="form-control" type="text" placeholder="Email">
                      <label for="floatingInput">Email</label>
                    </div>
                  </div>
                </div>
                
                <div class="row mb-3 justify-content-center">
                  <div class="col">
                    <div class="form-floating">
                      <input v-model="passwordForm.password" class="form-control" type="password" placeholder="Password">
                      <label for="floatingInput">Password</label>
                      <div v-if="formValidations.passwordError" class="invalid-feedback d-block fs-10">{{ formValidations.passwordError }}</div>
                    </div>
                  </div>
                </div>

                <div class="row mb-3 justify-content-center">
                  <div class="col">
                    <div class="form-floating">
                      <input v-model="tempForm.passwordControl" @change="validatePasswordForm()" class="form-control" type="password" placeholder="Repeat Password">
                      <label for="floatingInput">Repeat Password</label>
                      <div v-if="formValidations.passwordRepeatError" class="invalid-feedback d-block fs-10">{{ formValidations.passwordRepeatError }}</div>
                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-4 d-flex justify-content-start mb-2">
                    &nbsp;
                  </div>
                  <div class="col-8 d-flex justify-content-end text-end mb-2">
                    <button v-if="!loading" @click="submitPasswordForm()" type="submit" class="btn btn-primary">Change Password</button>

                    <div v-if="loading" class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>

                  </div>
                </div>
              </form>
            </div>

            <div v-if="passwordFormSuccess">
              <div class="alert alert-success border-0 d-flex align-items-center" role="alert">
                <div class="bg-success me-3 icon-item"><span class="fas fa-check-circle text-white fs-6"></span></div>
                <p class="mb-0 flex-1">Your password was updated successfully.</p>
              </div>
            </div>


          </div>
          <!-- <div class="card-footer">
            <div class="d-flex align-items-center justify-content-center">
            </div>
          </div> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import TheNavbar from '@/components/TheNavbar'
export default {
  components: {
    TheNavbar
  },
  data () {
    return {
      loading: false,
      tempForm: {
        passwordControl: ''
      },
      passwordFormSuccess: false,
      passwordForm: {
        password: ''
      },
      formValidations: {
      }
    }
  },
  computed: {
    userProfile () {
      return this.$store.getters.getUserProfile
    }
  },
  methods: {
    validatePasswordForm() {
      this.formValidations = {}

      // Remove spaces
      this.passwordForm.password = this.passwordForm.password.replace(/\s/g, '')
      this.tempForm.passwordControl = this.tempForm.passwordControl.replace(/\s/g, '')

      if (!this.passwordForm.password || this.passwordForm.password == '') {
        this.formValidations.passwordError = "Enter your new password."
        return false
      }

      if (this.passwordForm.password.length < 7) {
        this.formValidations.passwordError = "Your password must be at least 7 characters."
        return false
      }

      const passwordStrength = this.getPasswordStrength(this.passwordForm.password)
      if ( passwordStrength < 3) {
        this.formValidations.passwordError = "Your password is not very strong. It scores "+passwordStrength+" out of 4 points. Add capital LeTtErs, special characters $@#&! and/or numb3rs to strengthen it. It's for your own safety."
        return false
      }

      if (!this.tempForm.passwordControl || this.tempForm.passwordControl == '') {
        this.formValidations.passwordRepeatError = "Repeat the password to be sure you typed it correctly."
        return false
      }

      // TODO: Validate it properly
      if (this.passwordForm.password != this.tempForm.passwordControl) {
        this.formValidations.passwordRepeatError = "The two passwords don't match. Please check."
        return false
      }

      return true
    },
    getPasswordStrength(password) {
      var strength = 0
      if (password.match(/[a-z]+/)) {
        strength += 1
      }
      if (password.match(/[A-Z]+/)) {
        strength += 1
      }
      if (password.match(/[0-9]+/)) {
        strength += 1
      }
      if (password.match(/[$@#&!]+/)) {
        strength += 1
      }

      return strength
    },
    async submitPasswordForm () {
      this.loading = true

      if (this.validatePasswordForm()) {
        await this.$store.dispatch('updateUserProfile', this.passwordForm)
        this.passwordForm = {}
        this.tempForm = {}
        this.passwordFormSuccess = true
      }

      // TODO: Add visual DONE indicator

      this.loading = false
    }
  },
  mounted () {
    if (this.userProfile) {
      // TODO: Does this actually work?
      this.passwordForm.firstName = this.userProfile.firstName
      this.passwordForm.lastName = this.userProfile.lastName
    }
  },
  beforeMount () {
    // Redirect to Login when the user is not logged in
    if (!this.$store.getters.isUserLoggedIn) this.$router.push({ name: 'Login' })
  }
  
}
</script>

<style scoped>

</style>