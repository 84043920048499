<template>
  <TheNavbar />

  <div class="mt-5 mb-5">

    <div class="row justify-content-center mb-4">
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">

        <div class="card">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">Get Gaussian Channel Strategy Access</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            <p>
              Enter your TradingView username (e.g. moonboy123) below to get access to the Gaussian Channel Strategy. It needs your username not your email!
            </p>

            <form @submit.prevent="" class="needs-validation" novalidate>
                <div class="row mb-3 justify-content-center">
                  <div class="col">
                    <div class="form-floating">
                      <input v-model="tempForm.tvUsername" class="form-control" type="text">
                      <label for="floatingInput">Your TradingView Username</label>
                      <div v-if="formValidations.tvUsername" class="invalid-feedback d-block fs-10">{{ formValidations.tvUsername }}</div>
                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-4 d-flex justify-content-start mb-2">
                    <button @click="back" type="submit" class="btn btn-secondary">Back</button>&nbsp;
                  </div>
                  <div class="col-8 d-flex justify-content-end text-end mb-2">
                    <button v-if="!loading" @click="submitAccessForm()" type="submit" class="btn btn-primary">Get Access Now</button>

                    <div v-if="loading" class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>

                  </div>
                </div>
              </form>
          </div> <!-- card-body-->
        </div>

      </div>
    </div>

    
    <div class="row justify-content-center mb-3">
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">

        <div class="card">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">How to find your TradingView username</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            <p>
              Hover over your profile icon on the top left...<br>
              <img class="img-fluid rounded mx-auto mb-3" src="@/assets/strategies/tv_username.jpg" />
            </p>
          </div> <!-- card-body-->
        </div>

      </div>
    </div>
    
    <div class="row justify-content-center mb-3">
      <div class="col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6">

        <div class="card">
          <div class="card-header">
            <div class="row flex-between-end">
              <div class="col-auto text-center">
                <h5 class="mb-0" data-anchor="data-anchor">What to do next</h5>
              </div>
            </div>
          </div>
          <div class="card-body bg-body-tertiary">
            <p>
              <span class="fw-bold">Once you have access (confetti was flying), follow these steps:</span>
            </p>

            <ol>
              <li>Open Strategy: <a href="https://www.tradingview.com/script/TUxD9OoE-Gaussian-Channel-Strategy-v3-0/" target="_blank">https://www.tradingview.com/script/TUxD9OoE-Gaussian-Channel-Strategy-v3-0/</a></li>
              <li>Scroll down and click on "Add to favorites"</li>
              <li>Reload TradingView (F5 or CMD+R)</li>
              <li>Go back to your TradingView chart</li>
              <li>Click on Indicators in the top menu</li>
              <li>You'll now find it in your Favorites</li>
              <li>Click on it one-time to add it to your chart</li>
              <li>Done :)</li>
            </ol>

            <p>
              <span class="fw-bold">And, watch this tutorial video to learn how to use & automate it:</span>
            </p>
            <iframe width="100%" height="400" style="max-width: 800px;" src="https://www.youtube-nocookie.com/embed/aXP-gsw7kxE?si=a3XAEX5njS-SH0SP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

          </div> <!-- card-body-->
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import TheNavbar from '@/components/TheNavbar'
export default {
  components: {
    TheNavbar
  },
  data () {
    return {
      loading: false,
      tempForm: {
        tvUsername: ''
      },
      formValidations: {
      }
    }
  },
  computed: {
    getSupportUrl () {
      return process.env.VUE_APP_SUPPORT_URL
    }
  },
  methods: {
    validateAccessForm() {
      this.formValidations = {}

      // Remove spaces
      this.tempForm.tvUsername = this.tempForm.tvUsername.replace(/\s/g, '')

      if (!this.tempForm.tvUsername || this.tempForm.tvUsername == '') {
        this.formValidations.tvUsername = "Enter your TradingView username."
        return false
      }

      return true
    },
    async submitAccessForm () {
      this.loading = true      

      if (this.validateAccessForm()) {
        
        // Construct postData to send
        const postData = {
          pine_ids: ['PUB;4535cc424f954191bb0f3607bd651add'], // https://www.tradingview.com/script/TUxD9OoE-Gaussian-Channel-Strategy-v3-0/
          duration: '1L'
        }

        // Make an api call
        const url = 'https://tv-access.signum.money/access/'+this.tempForm.tvUsername // TODO: Set API endpoint
        const rawResponse = await fetch(url, {
          method: "POST",
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData), // body data type must match "Content-Type" header
        })

        if (rawResponse.status === 200) {
          const response = await rawResponse.json() // parses JSON response into native JavaScript objects
          
          if (response[0]['hasAccess'] || response[0]['status'] == 'Success') {
            // Reset variables
            this.tempForm = {}

            // Play confetti to make user happy
            this.$confetti.start()
            setTimeout(() => {
              this.$confetti.stop()
            }, "2000");
          }

        } else {
          try {
            const response = await rawResponse.json()
            this.formValidations.tvUsername = response.errorMessage
          } catch(e) {
            this.formValidations.tvUsername = 'An unknown error occurred.'
          }
        }
      }

      this.loading = false
    },
    back () {
      this.$router.push({ name: 'Strategies' })
    }
  },
  async created () {
    if (!this.$store.getters.getUserBots) await this.$store.dispatch('getUserBots')
  },
  beforeMount () {
    // Redirect to Login when the user is not logged in
    if (!this.$store.getters.isUserLoggedIn) this.$router.push({ name: 'Login' })
  }
  
}
</script>