<template>
  <router-view/>
  <div class="fs-11 mt-5 mb-3 text-center">&#169; SIGNUM | App Version v1.1</div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  async mounted () {
    const userProfile = JSON.parse(localStorage.getItem("userProfile"));
    if (userProfile) {
      this.$store.commit('setUserProfile', { userProfile: userProfile })
    }

    // This is a small hack to force it to fetch the newest profile infos from the API otherwise users need to reload the page before they see their subscription show up
    await this.$store.dispatch('getUserProfile')

    // Store new UTMs in local storage and load the existing ones into the store if they exist
    await this.$router.isReady()
    let newUTMs = {
      medium: this.$route.query.utm_medium,
      source: this.$route.query.utm_source,
      campaign: this.$route.query.utm_campaign,
      term: this.$route.query.utm_term,
      content: this.$route.query.utm_content
    }
    this.$store.commit('setUTMs', { utms: newUTMs })
  },
  async created () {
    // TODO: Do I really want this here?
    // if (!this.$store.state.platformExchanges) await this.$store.dispatch('getPlatformExchanges')
    
    // Only activate Posthog in the prod env
    if (process.env.VUE_APP_ENV != 'prod') this.$posthog.opt_out_capturing()
  }
}
</script>
